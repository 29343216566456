import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../../../components/PageWrapper";
import styled from "styled-components";
import SEO from "../../../components/seo";
import BackButton from "../../../components/Core/BackButton";
import PageTitle from "../../../components/Core/PageTitle";
import NoAuthRedirect from "../../../components/Core/NoAuthRedirect";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const Content = styled.div`

    $ a:hover {
        color: silver;
    }

    & h2 {
        margin: 1.5rem 0;
    }
`

const BlogArticle = ({data, location}) => {
    const headerConfig = {
        paddingBottom: 10,
    };

    const {content} = data.allSharePointPageContentList

    return (
        <>  
            <UnauthenticatedTemplate>
                <NoAuthRedirect location={location} />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <PageWrapper headerConfig={headerConfig}>
                    <SEO title="Cookies and Privacy Policy" />
                    <Content>
                        <BackButton />
                        <Container className="">
                            <Row className="">
                                <Col className="col-lg-2"></Col>
                                <Col className="col-lg-6">
                                    <PageTitle
                                        title="Leslie Martin shares recent experience"
                                        align="left"
                                    />
                                </Col>
                            </Row>
                            <Row className="">
                                <Col className="col-lg-2 text-right">
                                    <div className="mb-4 border-top pt-7">
                                        {/* Recent experience */}
                                    </div>
                                    <div>
                                        {/* Last updated<br />
                                        2 Jan, 2022 */}
                                    </div>
                                </Col>
                                <Col className="col-lg-6">
                                    <StaticImage
                                        src="photo_portrait_worker_desk_smiling.jpg"
                                        alt="a company meeting place"
                                        placeholder="blurred"
                                        layout="fixed"
                                        width={480}
                                        style={{maxWidth: '100%'}}
                                        className="mb-10"
                                    />
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.
                                    </p>
                                    <h2>
                                        Lorem ipsum dolor sit amet
                                    </h2>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.
                                    </p>
                                    <h2>
                                        Consectetur adipiscing elit aenean euismod bibendum
                                    </h2>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Content>
                </PageWrapper>
            </AuthenticatedTemplate>
        </>
    )
}

export default BlogArticle;

export const query = graphql`
    {
        allSharePointPageContentList( filter: {data: {fields: {Title: {eq: "cookies-and-privacy"}}}}) 
        {
            content: nodes {
                data {
                    fields {
                        Section
                        Content
                    }
                }
            }
        }
    }
`